import React from 'react'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'
import Section from '@components/Section'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { FaTrashAlt } from 'react-icons/fa'

const styles = {
    wrapper: src => ({
      alignItems: `center`,
      flexDirection: [`column`, `row`],
      bg: `omegaLighter`,
      backgroundImage: src && [`none`, `none`, `url(${src})`],
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `70% bottom`,
      borderRadius: `default`,
      width: `full`,
      p: 4,
      m: 2
    }),
    left: {
      flexBasis: `2/3`
    },
    right: {
      flexBasis: `1/3`,
      textAlign: `right`
    },
    heading: {
      color: `omegaDark`,
      //fontWeight: `normal`
    },
    subheading: {
      color: `omegaDark`,
      mb: [3, 0]
    }
  }
  
  const CardDeletion = ({...props}) => {
    const tutorialLink = <Text as={Link} to={props.tutorialURL}>here</Text>;
    return (
      <Flex sx={styles.wrapper}>
        <Box sx={styles.left}>
          <Heading variant='h3' sx={styles.heading}>
            {props.name}
          </Heading>
          <Text sx={styles.subheading}>{props.tutorial ? `Tutorial available on our website click ` : 'There is no tutorial yet'} 
          {props.tutorial && tutorialLink}</Text>
        </Box>
        <Box sx={styles.right}>
          <Button as={Link} to={props.url} variant='primary' aria-label='Delete my account'>
            Delete my account &nbsp;&nbsp; <FaTrashAlt />
          </Button>
        </Box>
      </Flex>
    )
  }

export default CardDeletion
