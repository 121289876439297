import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import CardDeletion from '@widgets/CardDeletion/CardDeletion'

const styles = {
    stack: {
        display: `flex`,
        flexDirection: `column`
    }
}

const data = {
    "items": [
      {
        "name": "eBay",
        "url": "https://cgi1.ebay.com/ws/eBayISAPI.dll?CloseAccount",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Facebook",
        "url": "https://www.facebook.com/help/delete_account?rdrhc",
        "tutorial": true,
        "tutorialURL": "how-to-delete-your-facebook-account"
      },
      {
        "name": "GoDaddy",
        "url": "https://account.godaddy.com/preferences",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Google",
        "url": "https://security.google.com/settings/security/deleteaccount",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Groupon",
        "url": "https://groupon.com/customer_support",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Hotmail",
        "url": "https://account.live.com/CloseAccount.aspx",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Instagram",
        "url": "https://instagram.com/accounts/remove/request/permanent/",
        "tutorial": true,
        "tutorialURL": "how-to-delete-your-instagram-account"
      },
      {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/help/linkedin/answer/63",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Netflix",
        "url": "https://help.netflix.com/en/node/407",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Pinterest",
        "url": "https://pinterest.com/settings/account-settings/",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Reddit",
        "url": "https://ssl.reddit.com/prefs/delete/",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Skype",
        "url": "https://support.skype.com/en/faq/FA142/can-i-delete-my-skype-account",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Stripe",
        "url": "https://dashboard.stripe.com/account/data",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Twitter",
        "url": "https://twitter.com/settings/accounts/confirm_deactivation",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Wikipedia",
        "url": "https://en.wikipedia.org/wiki/Wikipedia:FAQ#How_do_I_change_my_username.2Fdelete_my_account.3F",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "WordPress",
        "url": "https://wordpress.com/me/account",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "Yahoo!",
        "url": "https://edit.yahoo.com/config/delete_user",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      },
      {
        "name": "YouTube",
        "url": "https://myaccount.google.com/deleteservices",
        "tutorial": false,
        "tutorialURL": "https://howdelete.com/"
      }
    ]
  }

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Direct deletion links' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Delete your account using direct deletion links"
          subheader='FlexiBlog theme comes with a pre-made contact form component.
					You can integrate this form with serverless services such as Formspree, Getform,
					FormKeep and others to receive form submissions via email.'
        />
        <Divider />
        {data.items.map(data => <CardDeletion name={data.name} url={data.url} tutorial={data.tutorial} tutorialURL={data.tutorialURL}/>)}
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
